import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CRestaurantsTab,
  CSectTitle,
  CRestaurantsContents,
  RestaurantNav,
  LRestaurant,
  LRestaurantContact,
} from '../../../components/_index';

// markup
const SubPage = ({
  data,
  pageContext,
}: {
  data: any;
  pageContext: { currentid: string };
}) => {
  const news = data.microcmsRestaurantsMasterYrph;
  useEffect(() => {
    if (window && !window.location.hash) {
      window.scrollTo({
        top: 0,
      });
    }
  }, [])
  return (
    <Layout>
      <SEO
        title={"ティータイム"}
        description={
          '横浜ロイヤルパークホテル公式サイト。「ティータイム」のご案内。ランドマークタワー型のアフタヌーンティースタンドを使用したアフタヌーンティーをはじめ、68Fでお楽しみいただける中国料理のアフタヌーンティーもお楽しみいただけます。'
        }
        ogimage={news.eyecatch?.url}
      />
      <CJumbotron
        size="large"
        data={{
          title: {
            main: 'TEA TIME',
            sub: 'ティータイム',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/restaurants/teatime/kv.png',
              },
              imgSp: {
                src: '/assets/images/restaurants/teatime/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [
              {
                label: 'レストラン＆バー',
                path: '/restaurants/',
              },
            ],
            current: {
              label: 'ティータイム',
            },
          }}
        />
      </CJumbotron>
      <CRestaurantsTab
        restaurants="ティータイム・ラウンジ"
        basepath="teatime"
        currentid={pageContext.currentid}
      />
      <section className="l_sect">
        <LWrap>
          <CSectTitle title={{ ja: 'シーズンメニュー', en: 'SEASONAL MENU' }} />
        </LWrap>
      </section>
      <CRestaurantsContents data={news} description2={true} />
      <section className="u_mbLarge">
        <LWrap>
          <RestaurantNav />
        </LWrap>
      </section>
      <section>
        <LRestaurant />
      </section>
      <section className="l_sect">
        <LWrap>
          <ul className="c_noteList">
            <li>画像は全てイメージです。</li>
            <li>料金には消費税、サービス料が含まれます。</li>
            <li>料理の表示価格は、特別な記載がない限り1名様分の料金です。</li>
            <li>
              テイクアウト商品の料金には消費税（8％）のみ含まれます。コフレ店内をご利用の場合は、サービス料を加算、消費税は10％となります。
            </li>
            <li>入荷状況によりメニュー内容に変更がある場合がございます。</li>
            <li>
              「神奈川県公共的施設における受動喫煙防止条例」に基づきレストラン・バーを全席禁煙とさせていただいております。
            </li>
            <li>
              ご入店の際は、タンクトップ・短パン・ビーチサンダルなどはご遠慮いただいております。
            </li>
          </ul>
        </LWrap>
      </section>
      <LRestaurantContact />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($id: String!) {
    microcmsRestaurantsMasterYrph(id: { eq: $id }) {
      classification
      eyecatch {
        height
        url
        width
      }
      fair_title
      restaurantsMasterYrphId
      tabname
    }
  }
`;
